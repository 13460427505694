@import "../_variables.scss";

.list_container {
  position: relative;
  width: 100%;
}
.sidebar {
  background-color: white;
  padding: 8px 16px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08), 0 0 20px 0 rgba(0, 0, 0, 0.05);
  width: $sidebarWidth;
  position: fixed;
  height: 100%;
  a {
    padding: 4px 0;
  }
}

.title {
  font-size: 24px;
  margin-bottom: 16px;
}

.placeholder {
  margin: 0;
  align-items: center;
  justify-content: center;
  padding-left: $sidebarWidth;
  width: 100%;
}

.placeholder_text {
  font-style: italic;
  text-align: center;
  color: $lightGrey;
}
