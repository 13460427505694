//**************************RSUITE**********************//
.rs-calendar {
  border: none !important;
}

.rs-picker-daterange-panel {
  margin-top: 10px;
}
//Fix the bug of rsuite where the arrows has been inversed.
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
  .rs-calendar-header-forward {
  float: right;
}

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
  .rs-calendar-header-backward {
  float: left;
}

// Change the style for the cell that represent the current day
div.rs-calendar-table-cell.rs-calendar-table-cell-is-today.rs-calendar-table-cell-disabled
  > div {
  -webkit-box-shadow: inset 0 0 0 1px white;

  box-shadow: inset 0 0 0 1px #595959;
}

//put the placeholder at the center
div.rs-stack-item {
  display: flex;
  flex-direction: column;
}

// Change the background of the cell when hover
.rs-calendar-table-cell-content:hover {
  background-color: rgba(89, 89, 89, 0.7) !important;
  color: white !important;
}

//Change the background color of the selected cell
div.rs-calendar-table-cell.rs-calendar-table-cell-selected > div {
  background-color: #595959;
}

// For the cell in range
.rs-calendar-table-cell.rs-calendar-table-cell-in-range > div {
  background-color: #595959;
  color: white;
  border-radius: 0;
}

//For the cell in range and for the first and last one selected
.rs-calendar-table-cell.rs-calendar-table-cell-in-range,
.rs-calendar-table-cell.rs-calendar-table-cell-selected.rs-calendar-table-cell-selected-start,
.rs-calendar-table-cell.rs-calendar-table-cell-selected.rs-calendar-table-cell-selected-end {
  background-color: #595959;
  border-radius: 0;
}

//Change color of the month from red to black for current month
.rs-calendar-header-error,
.rs-calendar-header-title {
  color: black;
}

button.rs-calendar-header-title.rs-calendar-header-title-date.rs-btn.rs-btn-subtle.rs-btn-xs {
  font-weight: bold !important;
}

.rs-calendar-header-error:hover,
.rs-calendar-header-title:hover {
  background-color: rgba(89, 89, 89, 0.8);
}

.rs-calendar-table-cell,
.rs-calendar-table-header-cell {
  width: 0 !important;
  padding: 0 0;
}

.rs-picker-date-menu,
.rs-picker-menu {
  width: fit-content !important;
  margin-top: 6px;

  .rs-stack-item {
    width: 100% !important;
    min-width: 260px !important;
  }
}

.rs-picker-date,
.rs-picker-daterange {
  width: 100% !important;
  text-align: center !important;
}

.rs-picker-daterange-header {
  text-align: center;
}

// To make on month on top of the other
.rs-picker-daterange-calendar-group {
  display: flex;
  flex-direction: row;
  height: fit-content;
  width: 100%;
  min-width: 100px;
}

.rs-picker-toggle {
  height: 32px;
  border: none !important;
  &:hover {
    background: #e3e3e3 !important;
  }
  &:active {
    background: #f7f7f7 !important;
  }
}
//CHange the font color from grey to black for the selected values in the picker toggle.
.rs-picker-toggle-textbox,
.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: black;
}

.rs-btn-primary {
  background-color: rgba(89, 89, 89, 0.7) !important;
}

div.rs-picker-toggle.rs-btn.rs-btn-default {
  border-radius: 4px;
  text-align: left;
}

div.rs-picker-toggle.rs-btn.rs-btn-default:hover {
  border-color: rgba(89, 89, 89, 0.4) !important;
}

// Forcing grey color for the select calendar border when selected
div.rs-picker-toggle.rs-btn.rs-btn-default:focus,
div.rs-picker-toggle.rs-btn.rs-btn-default:focus-within,
div.rs-picker-toggle.rs-btn.rs-btn-default:focus-visible,
div.rs-picker-toggle.rs-btn.rs-btn-default:active,
div.rs-picker-toggle.rs-btn.rs-btn-default:target,
div.rs-picker-toggle.rs-btn.rs-btn-default:visited {
  border-color: #595959 !important;
  box-shadow: none !important;
}

//Removing the blue box shadow and border
.rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
  box-shadow: none !important;
  border-color: #595959 !important;
}

.rs-picker-toggle-wrapper {
  width: 240px;
  height: 34px !important;
}

//**************************REACT-SELECT**********************//

.css-1nmdiq5-menu {
  z-index: 40 !important;
}

// Center the text
.css-1nmdiq5-menu > div > div {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

//border at the bottom at each element
.css-1nmdiq5-menu > div > div:not(:first-child)::after {
  content: "";
  height: 1px;
  background: rgba(227, 227, 227, 1);
  position: absolute;
  top: 0;
  left: 5%;
  width: 90%;
}

.css-1nmdiq5-menu > div > div:hover {
  color: white;
  background-color: rgb(89, 89, 89);
}

.select-custom-calendar {
  height: 36px !important;
  width: 250px;
  z-index: 15 !important;
}

//Supress the small lateral bar
div.css-1hb7zxy-IndicatorsContainer > span {
  display: none;
}

.rs-picker-toggle-caret {
  top: 6px !important;
}
