@import "_variables.scss";

.bg_app {
  height: 100%;
}

.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.h100 {
  height: 100%;
}
