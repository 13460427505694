@use "sass:math";
@tailwind base;
@tailwind components;
@tailwind utilities;

// @import '~animate.css/source/animate.css';
// @import '_shared';
// @import '_variables.scss';

@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Vollkorn:ital,wght@0,400..900;1,400..900&display=swap");

html,
body {
  margin: 0;
  font-family: "Fira Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  * {
    box-sizing: border-box;
    margin: 0;
    font-family: "Fira Sans", sans-serif;
    user-select: none;
  }
}

#root {
  //padding-top: #{$headerHeight};
  //padding-left: #{$navWidth};
  height: 100%;
}

.main {
  height: 100%;
  position: relative;
  z-index: 0;
}
/*

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Vollkorn, serif;
  font-weight: normal;
  margin: 0;
}

a {
  text-decoration: none;
}

[aria-owns="react-select-1-listbox"],
[aria-owns="react-select-2-listbox"],
[aria-owns="react-select-3-listbox"],
[aria-owns="react-select-4-listbox"],
[aria-owns="react-select-5-listbox"],
[aria-owns="react-select-6-listbox"],
[aria-owns="react-select-7-listbox"],
[aria-owns="react-select-8-listbox"],
.rs-picker-date-menu,
.rs-picker-daterange-menu,
.rs-picker-daterange-panel {
  z-index: 15!important;
}*/
div[id^="react-select-"] {
  z-index: 30 !important;
}
.rs-picker-menu {
  z-index: 25 !important;
}

.vis-tooltip {
  position: absolute;
}

a.no-underline {
  text-decoration: none !important;
  &:hover {
    text-decoration: none !important;
  }
}


.titleA {
  overflow: hidden;
  color: #252525;
  fill: #252525;
  font-variant-numeric: lining-nums proportional-nums;
  text-overflow: ellipsis;
  /* Book Title/T2 */
  font-family: Vollkorn, serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 600;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.28px;
}.authorA {
   overflow: hidden;
   color: #666;
   fill: #66666699;
   text-align: center;
   text-overflow: ellipsis;
   /* Caption/C1 - Regular */
   font-family: Fira Sans, sans-serif;
   font-size: 12px;
   font-style: normal;
   font-weight: 400;
   line-height: 16px; /* 133.333% */
   letter-spacing: 0.24px;
}

.topIndex {
 z-index: 50;
}
.downIndex {
 z-index: 1;
}

.svg-image-center {
  position: relative;
  display: block;
  text-align : center;
}



/* width */
::-webkit-scrollbar {
  height: 5px;
  border-radius: 50%
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.grid-sizer {
  width: math.percentage(math.div(1,12));
}

.grid-item {
  margin-bottom: 16px;
}
