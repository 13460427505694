@import "../_variables.scss";

.navbar {
  background-color: white;
  border-right: 1px solid $lighterGrey;
  // position: fixed;
  padding: 8px;
  height: 100%;
  width: $navWidth;
  top: 0;
  left: 0;
  z-index: 1;
  a {
    padding: 4px 0;
  }
}
