$bg_color: #f7f7f7;
$darkGrey: #252525;
$grey: #595959;
$lightGrey: #b2b2b2;
$lighterGrey: #e3e3e3;

$headerHeight: 48px;
$sidebarWidth: 360px;
$navWidth: 56px;

:export {
  lighterGrey: $lighterGrey;
}
